@tailwind base;
@tailwind components;

@tailwind utilities;

@layer components {
  .flex-center {
    @apply flex flex-col items-center justify-center;
  }
}


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.slick-prev,
.slick-next {
  display: none !important;
}

.absolute-center{
  @apply absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2;
}
.fixed-center-cover{
  @apply fixed w-full h-full top-0 left-0 bg-black/50 flex-center;
}

.clip-path-polygon {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 30% 50%);
  }
  
/* @media only screen and (max-width: 600px){
    .sliderv{
        margin-top: 70px;
    }
    
} */