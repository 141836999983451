.animate-fall-in {
    animation: fall-in 0.5s ease-in-out;
  }


  
  
  @keyframes fall-in {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  