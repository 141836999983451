
/* flex items-center gap-2 bg-white rounded-lg md:w-2/4 lg:w-1/4 py-2 my-4 px-4 */
.form-class{
    @apply  flex flex-col items-center justify-center gap-4  py-4 shadow-lg;
}

.input-div{
    @apply flex relative bg-white items-center gap-2  py-2 ps-4 pr-8 rounded-lg
}

.input-div2{
  @apply flex relative items-center gap-2  py-2 ps-4 pr-8 
}

.input-div2 input{
  @apply focus:outline-none border-b-2 text-black border-slate-400
}


.input-div input{
    @apply focus:outline-none 
}

.btn{
    @apply  py-2 px-4 uppercase font-semibold transition-all hover:-translate-y-1
}
.form-class .button{
  @apply hover:bg-green-400 transition-all py-4 px-8 ;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}




