.admin .count{
    @apply md:h-32 md:w-32 flex-center rounded-full h-24 w-24 text-xl md:text-2xl;
}

.admin .sidebar-nav{
    @apply flex-center py-2 
}
.admin .side-links > *{
    @apply hover:bg-yellow-300;
}

.admin .side-links .activeSection{
    @apply bg-cyan-400;
}

/* Add CSS to hide columns in mobile view */
@media (max-width: 600px) {
    /* Replace 'age' with the accessor of the column you want to hide */
    .rt-th.createdAt,
    .rt-td.createdAt {
      display: none;
    }
  }
  